


































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    // avatar: () => import('@/modules/community/common/components/avatar.vue'),
    'group-cover': () => import('@/modules/community/common/components/group-cover.vue'),
    'follow-button': () => import('./follow-button.vue'),
  },
})
export default class FollowingItem extends Vue {
  @Prop() item?: any
}
